// mobile-menu
let mobileBtn = document.querySelector('.header__mobile-btn');
let header = document.querySelector('header');
mobileBtn.addEventListener('click', () => {
    mobileMenu(header)
});
function mobileMenu(header) {
    if (header.classList.contains('header__menu__active')) {
        header.classList.remove('header__menu__active');
        bodyCheck();
    } else {
        header.classList.add('header__menu__active');
        bodyCheck();
    }
}
// quality 
let qualitySlider = new Swiper('.quality__slider', {
    pagination: {
        el: document.querySelector('.quality__demonstration__btns'),
        type: 'custom',
        renderCustom: function (swiper, current, total) {
            let customPag = '';
            let index_currentSlide = swiper.realIndex;
            let currentSlide = swiper.slides[index_currentSlide]
            for (let i = 0; i < total; i++) {
                if (current - 1 === i) {
                    customPag += `
                    <button id="100" 
                    class="underline-btn quality__demonstration__btns__btn underline-btn--active " type="button"
                    data-index="${i}"
                    >
                        ${currentSlide.getAttribute('data-name')}
                    </button>
                    `;
                } else {
                    customPag += `
                    <button 
                    class="underline-btn quality__demonstration__btns__btn" type="button"
                    data-index="${i}"
                    >
                        ${swiper.slides[i].getAttribute('data-name')}
                    </button>
                    `;
                }
            }

            return customPag;
        },
    },
})
let changeBtns = document.querySelectorAll('.quality__demonstration__btns__btn');
reEventInit(changeBtns, qualitySlider, document)
qualitySlider.on('slideChange', () => {
    qualitySlider.update();
    reEventInit(changeBtns, qualitySlider, document);
    checkSquareBtn();
})
function checkSquareBtn() {
    let squareBtns = document.querySelectorAll('.quality__slider__btn');
    let qualitySlidesActive = document.querySelector('.quality__slider__item.swiper-slide-active');
    squareBtns.forEach(btn => {
        if (qualitySlidesActive.getAttribute('data-name') === btn.getAttribute('data-open')) {
            btn.classList.add('quality__slider__btn--active');
        } else {
            btn.classList.remove('quality__slider__btn--active');
        }

    })
}
function reEventInit(changeBtns, qualitySlider, document) {
    changeBtns = document.querySelectorAll('.quality__demonstration__btns__btn');
    changeBtns.forEach(btn => {
        btn.addEventListener('click', (e) => {
            let slideId = btn.getAttribute('data-index');
            qualitySlider.slideTo(slideId, 1000, true);
            reEventInit(changeBtns, qualitySlider, document)
        });
    });
}

let qualitySlides = document.querySelectorAll('.quality__slider__item');
qualitySlides.forEach((slide) => {
    let btnsContainer = slide.querySelector('.quality__slider__item__btns');
    for (let i = 0; i < qualitySlides.length; i++) {
        btnsContainer.insertAdjacentElement('beforeend', createSquareBtns(qualitySlides[i], i));

    }
});
function createSquareBtns(slide, index) {
    let name = slide.getAttribute('data-name');
    let squareBtn = document.createElement('button');
    if (slide.classList.contains('swiper-slide-active')) {
        squareBtn.className = "quality__slider__btn quality__slider__btn--active";
    } else {
        squareBtn.className = "quality__slider__btn";
    }
    squareBtn.setAttribute('data-open', name);
    squareBtn.setAttribute('data-slide-index', index)
    return squareBtn;
}
let squareBtns = document.querySelectorAll('.quality__slider__btn')
squareBtns.forEach(squareBtn => {
    squareBtn.addEventListener('click', () => {
        qualitySlider.slideTo(squareBtn.getAttribute('data-slide-index'), 1000, true);
    })
})
// let qualityBtns = document.querySelectorAll('.quality__demonstration__btns__btn');
// let qualitySlides = document.querySelectorAll('.quality__slider__item');
// let qualitySquareBtns = document.querySelectorAll('.quality__slider__btn');
// qualitySquareBtns.forEach(btn => {
//     btn.addEventListener('click', () => {
//         changeQualityItem(btn, qualityBtns)
//     })
// });
// qualityBtns.forEach(btn => {
//     btn.addEventListener('click', () => {
//         changeQualityItem(btn, qualityBtns)
//     })
// });
// function changeQualityItem(btn, qualityBtns) {
//     let name = btn.getAttribute('data-open');
//     let slide = document.querySelector(`.quality__slider__item[data-name="${name}"]`);
//     qualitySlidesClose(qualitySlides);
//     slide.classList.add('quality__slider__item--active');
//     qualitySlidesCheckButton(qualityBtns, name);
//     changeSquareBtns(qualitySquareBtns, name)

// }
// function changeSquareBtns(qualitySquareBtns, activeBtn) {
//     qualitySquareBtns.forEach(btn => {
//         btn.classList.remove('quality__slider__btn--active');
//         if (btn.getAttribute('data-open') == activeBtn) {
//             btn.classList.add('quality__slider__btn--active');
//         }
//     });
// }
// function qualitySlidesClose(qualitySlides) {
//     qualitySlides.forEach(slide => {
//         slide.classList.remove('quality__slider__item--active')
//     });
// }
// function qualitySlidesCheckButton(qualityBtns, activeBtn) {
//     qualityBtns.forEach(btn => {
//         btn.classList.remove('underline-btn--active');
//         if (btn.getAttribute('data-open') == activeBtn) {
//             btn.classList.add('underline-btn--active');
//         }
//     });
// }
// bg-close
let bgCloseContainer = document.querySelectorAll('._bg-close-container');
window.addEventListener('click', e => {
    bgCloseContainer.forEach(element => {
        bgClose(e, element)
    });

})
function bgClose(event, element) {
    let eTarg = event.target;
    if (!eTarg.closest('._bg-close')) {
        let selector = element.getAttribute('data-active');
        element.classList.remove(selector);
        document.body.classList.remove('body-lock');
    }
}
// headerScroll
let prevPos = 0
window.addEventListener('scroll', (e) => {
    let currentPos = pageYOffset;
    if (currentPos > prevPos && !header.classList.contains('header__menu__active') && currentPos > 20) {
        header.classList.add('header--hided')
    } else {
        header.classList.remove('header--hided')
    }
    prevPos = currentPos
});


function bodyCheck() {
    if (document.body.classList.contains('body-lock')) {
        document.body.classList.remove('body-lock');
    } else {
        document.body.classList.add('body-lock');
    }
}
// headerLinkScroll
let scrollLink = document.querySelectorAll('._scroll-link')
scrollLink.forEach(element => {
    element.addEventListener('click', e => {
        e.preventDefault()
        findLink(element, e)
    })
})
function changeActive(container, btn) {
    let items = container.querySelectorAll('._change-active');
    items.forEach(item => {
        if (item === btn) {
            item.classList.add('production-button--active');
        } else {
            item.classList.remove('production-button--active');
        }
    })
}
function findLink(link, e) {
    let linkWay = link.getAttribute('href').split('#')[1];
    if (link.classList.contains('_change-active')) {
        let container = link.closest('.production__block__description');
        changeActive(container, link)
    }
    let sectionPos = findSectionPos(linkWay) + scrollY;
    if (header.classList.contains('header__menu__active')) {
        mobileMenu(header);
    }
    window.scrollTo({
        top: + sectionPos - header.clientHeight,
        behavior: "smooth"
    })

}
function findSectionPos(sectionName) {
    let section = document.querySelector(`.${sectionName} `);
    return section.getBoundingClientRect().top;
}
//benefitsItemScripts
let benefitsBlock;
if (window.innerWidth > 780) {
    benefitsBlock = document.querySelector('.benefit__about__non-adaptive');
} else {
    benefitsBlock = document.querySelector('.benefit__about__adaptive')
}
let benfitsItems = benefitsBlock.querySelectorAll('._benefits-item');
benfitsItems.forEach(element => {
    checkBenefitItem(element);
})
function checkBenefitItem(element) {
    let img = element.querySelector('img').getAttribute('data-src');
    let text = element.querySelector('.benefit__item__popup-text').innerHTML;
    let id = element.getAttribute('data-id');
    let elStatus = element.getAttribute('data-from');
    createBenefitPopup(img, text, id, elStatus)
}
function createBenefitPopup(img, text, id, status) {
    let benefitPopup = document.createElement('div');
    let popupWay;
    if (status === '_from-left') {
        popupWay = 'benefit__popup--left';
    } else {
        popupWay = 'benefit__popup--right';
    }
    benefitPopup.className = `_bg-close benefit__popup ${popupWay} benefit__popup__${id}`;
    benefitPopup.setAttribute('data-opener-popup', id);
    benefitPopup.innerHTML = `
		<div class="benefit__popup__body">
			<img src="${img}" class="benefit__popup__body__img">
			<p class="benefit__popup__body__text text__document" style="line-height: 1.6;">
                ${text}
			</p>
		</div>
    `;
    document.body.append(benefitPopup);
}
let benefitBtns = benefitsBlock.querySelectorAll('._benefits-item');
benefitBtns.forEach(btn => {
    btn.addEventListener('mouseenter', (e) => {
        setTimeout(() => {
            openBenfitPopup(btn);
        }, 100)
    });
});

function openBenfitPopup(btn) {
    let id = btn.getAttribute('data-id');
    let popup = document.querySelector(`.benefit__popup[data-opener-popup="${id}"]`);
    popup.classList.add('popup--active');
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
        popup.addEventListener('mousemove', function handler() {
            popup.classList.remove('popup--active');
            document.body.style.overflow = '';
            popup.removeEventListener('mousemove', handler)
        })
    }, 500)
}
// how we do it scripts
let howBtns = document.querySelectorAll('._read-next-how');

howBtns.forEach(btn => {
    btn.addEventListener('mouseover', (e) => {
        openHowPopup(btn);
    });
});

function openHowPopup(btn) {
    document.body.style.overflow = 'hidden';
    let id = btn.closest('.how__item').getAttribute('data-id');
    let popup = document.querySelector(`.how__popup[data-open-how-popup="${id}"]`);
    popup.classList.add('how__popup__active');
    setTimeout(() => {
        popup.addEventListener('mouseover', function handler(e) {
            if (!e.target.closest('.how__popup__body')) {
                popup.closest('.how__popup').classList.remove('how__popup__active');
                document.body.style.overflow = '';
                popup.removeEventListener('mouseover', handler)
            }
        })
    }, 100)
}
// read next
let readNextBtns = document.querySelectorAll('._read-next-btn');
readNextBtns.forEach(btn => {

    btn.addEventListener('click', () => {
        checkReadNext(btn);
    })
});
function checkReadNext(btn) {
    let container = btn.closest('._hided-text-container');
    let hidden = container.querySelector('._hided-text');
    if (hidden.classList.contains('hided__text')) {
        hidden.classList.remove('hided__text');
        btn.innerHTML = 'Cвернуть';
    } else {
        hidden.classList.add('hided__text');
        btn.innerHTML = 'Читать далее';
    }

}
// production 

function buildSwiperSlider(sliderElm) {
    let item = sliderElm.closest('.production__item');
    let element = document.querySelector(`.production__item__two__img-slider[data-id="${sliderElm.getAttribute('data-id')}"]`)
    let slider = new Swiper(element, {
        //slidesPerView: 'auto',
        spaceBetween: 48,
        initialSlide: 1,
        pagination: {
            el: item.querySelector('.production__item__btns'),

            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let customPag = '';
                let index_currentSlide = swiper.realIndex;
                let currentSlide = swiper.slides[index_currentSlide]
                for (let i = 0; i < total; i++) {
                    if (current - 1 === i) {
                        customPag += `
                        <button  
                        class=" production-button production-button__small _change-item production__item__btns__btn production-button--active" type="button"
                        data-index="${i}"
                        >
							${currentSlide.getAttribute('data-id')} г
						</button>
                        `;
                    } else {
                        customPag += `
                        <button 
                        class="production-button production-button__small _change-item production__item__btns__btn " type="button"
                        data-index="${i}"
                        >
							${swiper.slides[i].getAttribute('data-id')} г
						</button>
                        `;
                    }
                }

                return customPag;
            },
        },
    });
    let changeBtns = item.querySelectorAll('._change-item');
    reEventInit(changeBtns, slider, item)
    slider.on('slideChange', () => {
        slider.update();
        reEventInit(changeBtns, slider, item);
    })
    function reEventInit(changeBtns, slider, item) {
        changeBtns = item.querySelectorAll('._change-item');
        changeBtns.forEach(btn => {
            btn.addEventListener('click', (e) => {
                let slideId = btn.getAttribute('data-index');
                slider.slideTo(slideId, 1000, true);
                reEventInit(changeBtns, slider, item)
            });
        });
    }
    return slider;
}
let productionItemSliders = document.querySelectorAll('.production__item__two__img-slider');
productionItemSliders.forEach(slider => {
    buildSwiperSlider(slider);
});
if (window.innerWidth <= 700) {
    let productionRows = document.querySelectorAll('.production__products__row');
    function buildSwiperRowSlider(slider) {
        let element = document.querySelector(`.production__products__row[data-id="${slider.getAttribute('data-id')}"]`)
        return slider = new Swiper(element, {
            slidesPerView: 'auto',
        });
    }
    productionRows.forEach(slider => {
        buildSwiperRowSlider(slider)
    })
}
